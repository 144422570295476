<template>
  <div class="performance">
    <div class="talent-list">
      <div class="talent-list-1">
        <router-link to>
          <div class="yeji">
            <p>我的业绩</p>
          </div>
        </router-link>
        <ul class="talent-list-1-b">
          <li>
            <p>累计收益</p>
            <p>
              ￥
              <span>259.50</span>
            </p>
            <div>
              <svg
                t="1598320950756"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="7748"
                width="14"
                height="14"
              >
                <path
                  d="M440.218071 1024v-818.448637h144.492824v818.448637z"
                  p-id="7749"
                  fill="#fc6f15"
                />
                <path
                  d="M493.16437 7.224641L318.74089 181.648121c-10.320916 10.320916-10.320916 26.834382 0 37.155298H703.711056c10.320916-10.320916 10.320916-26.834382 0-37.155298L531.351759 7.224641c-10.320916-9.288824-27.866473-9.288824-38.187389 0z"
                  p-id="7750"
                  fill="#fc6f15"
                />
              </svg>
              <p class="font1">
                今日+￥
                <span>0.00</span>
              </p>
            </div>
          </li>
          <li>
            <p>累计收益</p>
            <p>
              ￥
              <span>259.50</span>
            </p>
            <div>
              <svg
                t="1598320950756"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="7748"
                width="14"
                height="14"
              >
                <path
                  d="M440.218071 1024v-818.448637h144.492824v818.448637z"
                  p-id="7749"
                  fill="#fc6f15"
                />
                <path
                  d="M493.16437 7.224641L318.74089 181.648121c-10.320916 10.320916-10.320916 26.834382 0 37.155298H703.711056c10.320916-10.320916 10.320916-26.834382 0-37.155298L531.351759 7.224641c-10.320916-9.288824-27.866473-9.288824-38.187389 0z"
                  p-id="7750"
                  fill="#fc6f15"
                />
              </svg>
              <p class="font1">
                今日+￥
                <span>0.00</span>
              </p>
            </div>
          </li>
          <li>
            <p>累计收益</p>
            <p>
              ￥
              <span>259.50</span>
            </p>
            <div>
              <svg
                t="1598320950756"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="7748"
                width="14"
                height="14"
              >
                <path
                  d="M440.218071 1024v-818.448637h144.492824v818.448637z"
                  p-id="7749"
                  fill="#fc6f15"
                />
                <path
                  d="M493.16437 7.224641L318.74089 181.648121c-10.320916 10.320916-10.320916 26.834382 0 37.155298H703.711056c10.320916-10.320916 10.320916-26.834382 0-37.155298L531.351759 7.224641c-10.320916-9.288824-27.866473-9.288824-38.187389 0z"
                  p-id="7750"
                  fill="#fc6f15"
                />
              </svg>
              <p class="font1">
                今日+￥
                <span>0.00</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="talent-list-2">
        <p>查看订单明细</p>
        <svg
          t="1598407506136"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2087"
          width="16"
          height="16"
        >
          <path
            d="M704 512c0-8.288-3.232-15.776-8.4-21.456l0.08-0.064-320-352-0.08 0.08A31.76 31.76 0 0 0 352 128a32 32 0 0 0-32 32c0 8.288 3.232 15.76 8.4 21.456l-0.08 0.08L628.752 512 328.32 842.464l0.08 0.08A31.776 31.776 0 0 0 320 864a32 32 0 0 0 32 32 31.84 31.84 0 0 0 23.6-10.544l0.08 0.08 320-352-0.08-0.08c5.168-5.68 8.4-13.168 8.4-21.456z"
            p-id="2088"
            fill="#dedede"
          />
        </svg>
      </div>
    </div>
    <div class="performance-warp">
        <div class="performance-warp-top">
            <p>收益数据</p>
            <p>实时</p>
        </div>
        <ul class="performance-warp-center">
            <li class="selected">
                <p>今日</p>
            </li>
            <li>
                <p>昨日</p>
            </li>
            <li>
                <p>近七日</p>
            </li>
            <li>
                <p>全部</p>
            </li>
        </ul>
    </div>
  </div>
</template>
<style lang="scss">
.performance {
  width: 100%;
  height: 100%;
  background: #fff;
  .my-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    box-sizing: border-box;
    font-size: 0.38rem;
    background: #f4f4f4;
  }
  .talent-list {
    width: 7.05rem;
    margin: 0 auto;
    background: #fff;
    margin-top: 0.38rem;
    padding-bottom: 0.16rem;
    box-sizing: border-box;
    .talent-list-1 {
      width: 100%;
      .talent-list-1-b {
        display: flex;
        padding-left: 0.44rem;
        padding-top: 0.44rem;
        box-sizing: border-box;
        li {
          width: 30%;
          // text-align: center;
          margin-right: 0.3rem;
          p:nth-child(1) {
            font-size: 0.23rem;
            margin-bottom: 0.35rem;
          }
          p:nth-child(2) {
            font-size: 0.19rem;
            color: #505050;
            margin-bottom: 0.2rem;
            span {
              font-size: 0.23rem;
            }
          }
          div {
            display: flex;
            .font1 {
              color: #fc6f15;
            }
          }
        }
      }
      .yeji {
        height: 0.92rem;
        line-height: 0.92rem;
        border-bottom: 1px solid #f4f4f4;
        font-size: 0.3rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        svg {
          margin-top: 0.3rem;
        }
      }
      .talent-list-3 {
        padding-left: 0.5rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        li {
          width: 30%;
          margin-right: 0.2rem;
          p {
            font-size: 0.22rem;
            color: #505050;
            margin-bottom: 0.36rem;
          }
          p:nth-child(2) {
            margin-bottom: 0;
          }
        }
      }
    }
    .talent-list-2 {
      border-top: 1px solid #f4f4f4;
      height: 1.08rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p{
          font-size: 0.25rem;
          color: #888888;
      }
    }
  }
  .performance-warp{
      width: 100%;
      padding: 0.49rem 0.24rem 0 0.22rem;
      box-sizing: border-box;
      margin-top: 0.26rem;
      .performance-warp-top{
          display: flex;
          justify-content: space-between;
          p:nth-child(1){
              font-size: 0.3rem;
              color: #181818;
          }
          p:nth-child(2){
              font-size: 0.24rem;
              color: #d0d0d0;
          }
      }
      .performance-warp-center{
          width: calc(100% - 0.48rem);
          height: 0.68rem;
          background: #f6f6f6;
          display: flex;
          border-radius: 0.3rem;
          margin-top: 0.41rem;
          padding: 0.04rem;
          li{
             width: 25%;
             border-radius: 0.26rem;
             font-size: 0.26rem;
             color: #b1b1b1;
             text-align: center;
             line-height: 0.68rem;
          }
          .selected{
              background: #fff;
              color: #000000;
              border-radius: 0.2rem;
          }
      }
  }
}
</style>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>